import React from 'react';
import { Loader } from 'react-feather';


//
//
export default function Loading(props){

  return (
    <div className={`_loading ${props.cssClass} ${props.status ? ' ' : ' hide'}`}>
      <Loader size={36} className="animate-spin text-secondary"/>
    </div>
  )

}
