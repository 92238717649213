import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'


// components
import Layout from '../components/Layout'


//
//
export default function PageDrop(props){

    
    
    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>NFT Drops | Authentify Art NFT</title>
            </Helmet>


                
                <div className="card dark bg-three">
                    <h2>NFT Drops</h2>
                    <p>
                        This wallet represent your NFT Tokens from your connected wallet address. You can filter your token list by collection, send and recive NFT tokens.
                    </p>
                    <div className='flex flex-col sm:flex-row gap-4'>
                        <button type="button" className='button secondary'>Discover Drops</button>
                        <button type="button" className='button secondary outline'>All Drops</button>
                    </div>
                </div>

                <div className="card light bg-two bg-center mt-8">
                    <h3>NFT Drops</h3>
                    <p>
                    #StandUpForArt are people using the technology of the 2020's for good to build ethical ecosystems and markets that provide Access, Accountability, and Trust. This will lead to a much larger healthy marketplace and art will finally become the next great asset class! The time is now.
                    </p>
                    <div className='flex flex-col md:flex-row gap-2 mb-8'>
                        <div className='label light'><span className="icon-star mr-2"></span>Avaliable: 10/100 NFT</div>
                        <div className='label light'><span className="icon-tag mr-2"></span>Price: FREE</div>
                        <div className='label light'><span className="icon-clock mr-2"></span>Ends at: 2022-03-31 12:00:00 UTC</div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-4'>
                        <a href="https://www.standupfor.art/" target={'_blank'} rel="norefferer" className='button primary'>Claim NFT</a>
                        {/* <button type="button" className='button primary outline'>List of NFTs</button> */}
                    </div>
                </div>

                {/* <div className="card light bg-six mt-8">
                    <h3>Nexthash Drop</h3>
                    <p>
                        Santa Drop is an pilot NFT project by Nexthash. You will get your own Santa NFT presents for Xmass. Claim Santa tokens now!
                    </p>
                    <div className='flex flex-col md:flex-row gap-2 mb-8'>
                        <div className='label light'><span className="icon-star mr-2"></span>Avaliable: 10/100 NFT</div>
                        <div className='label light'><span className="icon-tag mr-2"></span>Price: FREE</div>
                        <div className='label light'><span className="icon-clock mr-2"></span>Ends at: 2021-12-31 12:00:00 UTC</div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-4'>
                        <button type="button" className='button primary'>Claim Santa NFT</button>
                        <button type="button" className='button primary outline'>List of NFTs</button>
                    </div>
                </div>

                <div className="card light bg-five mt-8">
                    <h3>Another Drop</h3>
                    <p>
                        Santa Drop is an pilot NFT project by Nexthash. You will get your own Santa NFT presents for Xmass. Claim Santa tokens now!
                    </p>
                    <div className='flex flex-col md:flex-row gap-2 mb-8'>
                        <div className='label light'><span className="icon-star mr-2"></span>Avaliable: 10/100 NFT</div>
                        <div className='label light'><span className="icon-tag mr-2"></span>Price: FREE</div>
                        <div className='label light'><span className="icon-clock mr-2"></span>Ends at: 2021-12-31 12:00:00 UTC</div>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-4'>
                        <button type="button" className='button primary'>Claim Santa NFT</button>
                        <button type="button" className='button primary outline'>List of NFTs</button>
                    </div>
                </div> */}



        </Fragment>
    );

}
