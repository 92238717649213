import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom';


// components
import Layout from '../components/Layout'


export default function Page404(props){


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>404 not found | Authentify Art NFT</title>
      </Helmet>


            <div className="main justify-center landing">

              <div className="container">
                <div className="mx-auto px-8 py-40 w-5/6 md:w-1/2 lg:w-[458px]">

                  <h1 className="text-center text-8xl">404</h1>
                  <h3 className="h3 text-center">Page not found</h3>
                  <p className="text-center mt-5 mb-8">The requested page does not exist, or may have been deleted.</p>
                  
                  <div className="text-center">
                    <Link to={'/'} className="button primary w-full">Back to the start page</Link>
                  </div>

                </div>
              </div>

            </div>

    </Fragment>
  );

}
