import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react';
import { HelmetProvider } from 'react-helmet-async';

// services
import { store, persistor } from './controllers/redux/Store'
import Router from './controllers/Router';

const helmetContext = {};

function App() {

    return (
      <Provider store={store}>
          <HelmetProvider context={helmetContext}>
          <PersistGate loading={'Loading...'} persistor={persistor}>
              <Router />
          </PersistGate>
          </HelmetProvider>
      </Provider>
    );
}

export default App;