import React, { Fragment } from 'react'


//
//
export default function AccordionItem(props) {


    const [ state, setState ] = React.useState(false)


    //
    //
    return (
        <Fragment>
            <div className={`${state ? 'pb-8' : 'pb-2'} px-12 md:px-16 rounded-3xl bg-gray-light mt-7 overflow-hidden`}>
                <button type="button" className='flex flex-row justify-between items-center w-full pt-8 pb-6' onClick={()=>setState(!state)}>
                    <span className='font-bold font-serif text-left'>{props.title}</span>
                    <span className={`${state ? 'icon-minus' : 'icon-plus'} text-xl ml-2`}></span>
                </button>
                <div className={`${state ? 'scale-y-100' : 'scale-y-0 h-0 opacity-0'} transition duration-200`}>
                    {props.children}
                </div>
            </div>
        </Fragment>
    );
};
