import detectEthereumProvider from '@metamask/detect-provider';


// controllers
// import { _getAccountType } from './SmartContracts'
import { _log, _timeout } from './Helpers'


//
//
const _addNetwork = async() => {

    try {
        const connect = await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
        });
        _log('_addNetwork', connect);
        return true;
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{ 
                        chainId: '0x38', 
                        rpcUrls: ['https://bsc-dataseed.binance.org/'],
                        chainName: 'Binance Smart Chain'
                    }],
                });
                await _timeout(500)
                return true
            } catch (addError) {
                // handle "add" error
                _log('_addNetwork | addError', addError, 'error');
                return false;
            }
        }else{
            // handle other "switch" errors
            _log('_addNetwork | switchError', switchError, 'error');
            return false;
        }
    }

}


//
//
export const _connectionStatus = async (sellectedAccountType=null, providerName='metamask') => {

    //
    const provider = await detectEthereumProvider();
    if(!provider || !provider._state){
        return {status:false, error:'x0'}
    }
    // const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    // _log('provider', [provider._state]);

    //
    if(!provider._state?.isConnected){
        return {status:false, error:'x1'}
    }

    //
    if(!provider._state?.isUnlocked){
        return {status:false, error:'x2'}
    }

    //
    if (provider !== window.ethereum) {
        return {status:false, error:'x4'}
    }

    const address = window.ethereum.selectedAddress
    if(!address){
        return {status:false, error:'x3'}
    }

    // const add = await _addNetwork()
    // if(!add){
    //     return {status:false, error:'x5'}
    // }

    // const account = await _getAccountType(address)
    // if(!account){
    //     return {status:false, error:'x6'}
    // }

    // let currentAccount = account.type
    // if(sellectedAccountType){
    //     currentAccount = sellectedAccountType
    // }

    return {
        status:true, 
        provider:providerName, 
        address:address, 
        // accountType:account.type, 
        // currentAccount:currentAccount, 
        // tokenId:account.tokenId
    }

}


//
//
export const _metaMaskConnect = async () => {

    if (!window.ethereum) {
        return {
            status: false,
            message: 'no MetaMask'
        }
    }
    
    if (window.ethereum?.selectedAddress) {
        _log(window.ethereum.networkVersion);
        return{
            status: true,
            address: window.ethereum.selectedAddress
        }
    } else {
        await window.ethereum?.request({ method: 'eth_requestAccounts' });
        return {
            status: false,
            message: 'no selected address'
        }
    }
}


//
//
export const  _providerErrors = (data) => {

    if(data && data.error){

        if(data.error==='x0'){
            _log('_connectionStatus',['x0', 'No MetaMask provider']);
            return {message: 'No <strong>MetaMask</strong> provider'}
        }

        if(data.error==='x1'){
            _log('_connectionStatus',['x1', 'Please connect MetaMask and select account.']);
            return {message: 'Please connect <strong>MetaMask</strong> and select account.'}
        }

        if(data.error==='x2'){
            _log('_connectionStatus',['x2', 'Please unlock MetaMask and select account.']);
            return {message: 'Please unlock <strong>MetaMask</strong> and select account.'}
        }

        if(data.error==='x3'){
            _log('_connectionStatus',['x3', 'Please select account in MetaMask']);
            return {message: 'Please select account in <strong>MetaMask</strong>'}
        }

        if(data.error==='x4'){
            _log('_connectionStatus',['x4', 'Do you have multiple wallets installed?']);
            return {message: 'Do you have multiple wallets installed?'}
        }

        if(data.error==='x5'){
            _log('_connectionStatus',['x5', 'You must add BSC network']);
            return {message: 'You must add BSC network'}
        }

        if(data.error==='x6'){
            _log('_connectionStatus',['x5', 'You must be enrolled to selected network']);
            return {message: 'You must be enrolled to selected network'}
        }

    }

    return false;

}