import React, { Fragment } from 'react'

// components
import Footer from '../Footer'
import Header from '../Header'


//
//
export default function Layout(props) {


  //
  //
  return (
    <Fragment>
      <div className="container max-w-6xl px-4 xl:px-0 mx-auto">
        
        <Header />

        {props.children}

        <Footer/>

      </div>
    </Fragment>
  );
};
