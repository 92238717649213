import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'


// controlers
import { _listNFT } from '../controllers/SmartContract'
import { _apiGETone } from '../controllers/Api'


// components
import Loading from '../components/Loading'
import NFTitem from '../components/NFTitem'
import Modal from '../components/Modal'


//
//
export default function PageWallet(props){

    const connected = useSelector(state => state.auth.response);
    const [ isLoading, setLoading ] = React.useState(true)
    const [ totalSelected, setTotalSelected ] = React.useState(0)
    const [ ntfList, updateNFTlist ] = React.useState([])

    // modal - send
    const [ modalSend, setModalSend ] = React.useState(false)
    const [ sendToAddress, setSendToAddress ] = React.useState('')
    const [ sendError, setSendError ] = React.useState(false)


    // 
    // 
    //
    //
    const _getTokens = async(address) => {

        // credits(orderBy: createdAtTimestamp, orderDirection: desc, first:${limit}, skip:${currentPage>0 ? currentPage*limit : 0}, where:{tokenId:${parseInt(7680)}}) {
        const tokensQuery = `
        {
            users(  
              where: {id: "${address}"}) {
              id
              tokens {
                      id
                tokenID
                contentURI
                name      
                createdAtTimestamp
              }
            }
          }
        `

        const client = new ApolloClient({
            uri: 'https://api.thegraph.com/subgraphs/name/omnitaskba/authart',
            cache: new InMemoryCache(),
        })

        client
        .query({
            query: gql(tokensQuery),
        })
        .then(async(data) => {

            console.log('_getTokens: ', data.data.users)
            let tokens = []
            if(data.data.users.length>0){
                for (let i1 = 0; i1 < data.data.users.length; i1++) {
                    const user = data.data.users[i1];
                    if(user.tokens.length>0){
                        for (let i2 = 0; i2 < user.tokens.length; i2++) {
                            const token = user.tokens[i2];
                            const tokenData = await _apiGETone(token.contentURI)
                            if(!tokenData.error){
                                console.log('tokenData',tokenData)
                                tokens.push(tokenData)
                            }
                        }
                    }
                }
            }
            updateNFTlist(tokens)
            // const newArray = data.data.credits.find(x => x.packageName==='Seventypackage')
            // if(newArray){
            //     setHasSeventyPackage(true)
            // }
            // setDataTransactions(data.data.credits)
            // setLoading(false)
            // if(data.data.credits.length===0){
            //     setLastPage(true)
            // }else{
            //     setLastPage(false)
            // }

        })
        .catch((err) => {
            console.log('Error fetching data: ', err)
            setLoading(false)
        })
    }


    //
    //
    const _selectNFT = (tokenId) => {
        let newArray = [...ntfList]
        const index = newArray.findIndex(x => x.tokenId===tokenId)
        if(index !== -1){
            newArray[index].selected = !newArray[index].selected
        }
        updateNFTlist(x => newArray)
    }


    //
    //
    React.useEffect(()=>{
        const init = async()=>{
            if(connected.status){
                setLoading(true)
                // const get = await _listNFT(connected.address)
                const get = await _getTokens(connected.address)
                // updateNFTlist(get)
            }
            setLoading(false)
        }
        init()
    },[connected])


    //
    //
    React.useEffect(()=>{
        setTotalSelected(ntfList.filter(x => x.selected).length)
    },[ntfList])

    
    
    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>My NFT Wallet | Authentify Art NFT</title>
            </Helmet>


                
                <div className="card dark bg-one  bg-center">
                    <h2>My NFT Wallet</h2>
                    <p>
                        This wallet represent your NFT Tokens from your connected wallet address. You can filter your token list by collection, send and recive NFT tokens.
                    </p>
                    {!isLoading && connected.status && ntfList.length>0 && 
                    <div className='flex flex-col sm:flex-row gap-4'>
                        <button type="button" className='button secondary'>Receive NFT</button>
                        <button type="button" className='button secondary' onClick={()=>setModalSend(true)}>Send NFTs {totalSelected>0 && '('+totalSelected+')'}</button>
                        <button type="button" className='button secondary outline'>All Collections</button>
                    </div>
                    }
                </div>


                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 my-8'>
                    {ntfList.length>0 && ntfList.map((item,index) => {
                        return(
                            <NFTitem
                                key={index}
                                author={'AUTHArt'}
                                collection={item.name}
                                tokenId={item.tokenId}
                                image={item.image}
                                selected={item.selected ? true : false}
                                action={_selectNFT}
                            />
                        )
                    })}
                </div>

                {!isLoading && !connected.status && <div className='text-center pb-7'>You must be connected to metamask to acccess your NFT tokens.</div>}

                <Modal
                    status={modalSend}
                    close={()=>setModalSend(false)}
                    size="small"
                    showClose={true}
                >
                    <div className=''>
                        <h4 className='font-serif font-bold text-xl lg:text-2xl mb-4 max-w-xl text-center'>Send NFT token</h4>
                        <p className='mb-6 text-center text-sm'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium, eros sit amet dapibus finibus, felis ligula facilisis massa, eget maximus ipsum velit ut eros. 
                        </p>
                        <div className='mb-6 formGroup'>
                            <label>Address</label>
                            <input type="text" className='formControl' value={sendToAddress} onChange={(e)=>setSendToAddress(e.target.value)} />
                        </div>
                        <button type="button" className='button secondary w-full' disabled={sendToAddress==='' ? true : false}>Send NFT</button>
                    </div>
                </Modal>


                <Loading status={isLoading}/>


        </Fragment>
    );

}
