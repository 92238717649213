const auth = (state = {}, action) => {
  switch(action.type){
    case "AUTHSTATUS":
      return {
        ...state,
        status: action.status,
        response: action.response
      }
    case "AUTHUPDATE":
      return {
        ...state,
        status: action.status || state.status,
        response: action.response || state.response
      }
    case "AUTHLOGOUT":
      return {
        ...state,
        status: false,
        response: null
      }
    default:
      return state
  }
}

export default auth;
