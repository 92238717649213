import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'


// controllers
import _actions from './redux/actions';
import { _connectionStatus } from './MetaMask'
import { _log, _timeout } from './Helpers'
import history from './History';


// components
import Loading from '../components/Loading';
import Layout from '../components/Layout';

// pages
import PageHome from '../pages/Homepage';
import PageWallet from '../pages/Wallet';
import PageDrop from '../pages/Drop';
import PageTutorial from '../pages/Tutorial';


// default - public
import Page404 from '../pages/404';
import Page403 from '../pages/403';


//
//
function AppRouter() {

  const dispatch = useDispatch()
  const [ connected, setConnected ] = React.useState(false)


  //
  //
  const _init = async () => {

    let connect = await _connectionStatus()
    if(connect.status){
        await dispatch(_actions.auth.authUpdate({ 
          ...connect
        }))
    }else{
      await dispatch(_actions.auth.authUpdate({ 
        ...connect
      }))
    }
    
    return connect

  }


  //
  //
  React.useEffect(()=>{

    if(window.ethereum){

      window.ethereum.on('connect', (info) => { 
        _log('Router | window.ethereum | connect',info) 
      });
      window.ethereum.on('disconnect', (info) => { 
        _log('Router | window.ethereum | disconnect',info) 
        window.location.reload()
      });
      window.ethereum.on('chainChanged', async (_chainId) => { 

        const get  = await _init()
        _log('Router | window.ethereum | chainChanged',[_chainId,get]); 
        window.location.reload()

      });
      window.ethereum.on('accountsChanged', async (info) => { 
        
        await _timeout(500)
        const get  = await _init()
        _log('Router | window.ethereum | accountsChanged',[info,get]);

      });
      window.ethereum.on('message', async (info) => { 
        _log('Router | window.ethereum | message',info); 
      });

    }

  },[window.ethereum])


  //
  //
  React.useEffect(()=>{

    const getStatus = async () =>{
      await _timeout(500)
      const get  = await _init()
      _log('Router init/reload | Connection status',get)
      setConnected(get)
    }
    getStatus()

    return (() => [getStatus])
  },[])


  //
  //
  return (
    connected ? 
    <Router basename={'/'} history={history}>
      <Suspense fallback={<Loading status={true}/>}>
        <Layout>
            <Routes>

              {/* PUBLIC ROUTES */}
              <Route exact path="/" element={<PageHome />}/>
              <Route exact path="/wallet" element={<PageWallet />}/>
              <Route exact path="/drop" element={<PageDrop />}/>
              <Route exact path="/tutorial" element={<PageTutorial />}/>

              {/* ERROR ROUTES */}
              <Route exact path="/404" element={<Page404 />}/>
              <Route exact path="/403" element={<Page403 />}/>
              <Route path="*" element={<Page404 />}/>

            </Routes>
              
            <ToastContainer
              position="top-right"
            />
        </Layout>
      </Suspense>
    </Router>
    :
    <Loading 
      status={true}
    />
  );
}

export default AppRouter;

// <Route exact path="/:permalink">
//   <PageGeneric sidebar={_sidebar}/>
// </Route>
