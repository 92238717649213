import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'


// components
import Layout from '../components/Layout'


//
//
export default function PageHome(props){

    
    const connected = useSelector(state => state.auth.response);

    
    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Authentify Art NFT</title>
            </Helmet>


                
                <div className="card dark bg-one bg-center">
                    <h2>Welcome to NFT Token Platform.</h2>
                    <p>
                        The Singularfty platform is a unique NFT marketplace, where anyone can find, claim and buy limited edition digital NFT tokens available to any collector. Discover and invest in the digital art assets of your choice. 
                        Don’t miss your opportunity to own something special, valuable and scarce. Start collecting today!
                    </p>
                    {!connected.status && <button type="button" className='button secondary'>Connect wallet</button>}
                </div>


                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 my-8'>

                    <div className='rounded-3xl bg-gray-light p-10 flex flex-col justify-between items-center'>
                        <div className='flex flex-col items-center'>
                            <span className='icon-wallet text-4xl text-secondary'></span>
                            <h3 className='font-serif font-bold text-2xl text-center my-6'>Connect with MetaMask</h3>
                            <p className='text-center'>
                                Connect your crypto wallet with MetaMask for quick and easy interaction with the Ethereum blockchain.
                            </p>
                        </div>
                        <button type="button" className='label light alt mt-6 mx-auto'><span className='icon-help text-lg mr-2'></span>How to use MetaMask</button>
                    </div>

                    <div className='rounded-3xl bg-gray-light p-10 flex flex-col justify-between items-center'>
                        <div className='flex flex-col items-center'>
                            <span className='icon-parachute text-4xl text-secondary'></span>
                            <h3 className='font-serif font-bold text-2xl text-center my-6'>Claim NFT Tokens from Drop</h3>
                            <p className='text-center'>
                                Claim your NFT tokens from the Drop section and don't miss out on the limited edition collections of NFT art available only on the Authentify platform.
                            </p>
                        </div>
                        <button type="button" className='label light alt mt-6 mx-auto'><span className='icon-help text-lg mr-2'></span>How to Claim NFTs</button>
                    </div>

                    <div className='rounded-3xl bg-gray-light p-10 flex flex-col justify-between items-center'>
                        <div className='flex flex-col items-center'>
                            <span className='icon-star text-4xl text-secondary'></span>
                            <h3 className='font-serif font-bold text-2xl text-center my-6'>Send and Receive NFT Tokens</h3>
                            <p className='text-center'>
                                Send and receive NFT collectable tokens directly from your wallet on the platform. Store them on separate wallets or send them as a gift. 
                            </p>
                        </div>
                        <button type="button" className='label light alt mt-6 mx-auto'><span className='icon-help text-lg mr-2'></span>How to Transfer NFTs</button>
                    </div>

                </div>



        </Fragment>
    );

}
