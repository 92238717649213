import { ethers } from 'ethers';
import dayjs from 'dayjs'
// import * as IPFS from 'ipfs-core'



// controllers
import { _toaster, _log } from './Helpers'


//
// Abi's
import NFTcontract from '../contracts/NftContract.json'
// import TransferableDropContract from '../contracts/TransferableDropContract.json'


//
// Addresses Rinkeby Test Network
let NFTaddress = "0x342aBd082c2ad83B766940AD2726D5eD8f3A6384";
// let TransferableDropAddress = "0x342aBd082c2ad83B766940AD2726D5eD8f3A6384";


//
//
let PROVIDER = null;
let SIGNER = null;
if(window.ethereum){
    PROVIDER = new ethers.providers.Web3Provider(window.ethereum);
    SIGNER = PROVIDER.getSigner();
}
const MAX_ALLOWANCE = ethers.BigNumber.from('0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')

const contractNft   = new ethers.Contract(NFTaddress, NFTcontract.abi, SIGNER)





//
//
export const _listNFT = async (address) => {

    try {

        // const assets = await window.ethereum.request({
        //     method: 'wallet_watchAsset',
        //     params: {
        //         type: 'ERC20',
        //         options: {
        //           address: address,
        //           symbol: 'AAN',
        //           decimals: 0,
        //           image: 'https://lh3.googleusercontent.com/02DWVCLA_1GN-tsenYRoPv53gdanTUHYJW2M4OM-Y2rUCiPUGbUgfM7qvomItFwolhKY9kD1y_jyXZVnatagufHho8MM2MI2V4dcYg=w600',
        //         },
        //       },
        // })
        // _log('_listNFT assets', assets, 'info')


        const nftTokens = []
        // const blanaceOf = await contractNft.balanceOf(address);
        // const total = parseInt(blanaceOf)
        // if(total>0){
        //     const node = await IPFS.create({repo: 'ok' + Math.random()})
        //     for (let index = 0; index < total; index++) {
        //         const getToken = await contractNft.tokenOfOwnerByIndex(address, index);
        //         if(getToken){
        //             const tokenUri = await contractNft.tokenURI(getToken._hex)
        //             if(tokenUri){

        //                 const stream = node.cat(tokenUri.substr(7))
        //                 let data = ''
        //                 for await (const chunk of stream) {
        //                     // chunks of data are returned as a Buffer, convert it back to a string
        //                     data += chunk.toString()
        //                 }
        //                 const tokenData = JSON.parse(data)
        //                 nftTokens.push({...tokenData, tokenId:parseInt(getToken._hex), image:'https://gateway.pinata.cloud/ipfs/'+tokenData.image.substr(7)})

        //             }
        //         }
        //     }
        // }
        _log('_listNFT', nftTokens, 'info')
        return nftTokens

    } catch (error){
        _log('_listNFT', error, 'error')
        return []
    }

}